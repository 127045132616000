import React, { useEffect, useState } from "react";
import { GeneralService } from "../../services";
const RevocationsComponent = () => {
    const [revocationsData, setRevocationsData] = useState([]);

    useEffect(() => {
        const fetchRevocations = async () => {
            try {
                const data = await GeneralService.getRevocations();
                setRevocationsData(data);
            } catch (error) {
                console.error('Error fetching revocations data:', error);
            }
        };

        fetchRevocations();
    }, []);

    return (
        <div>
            <pre>{JSON.stringify(revocationsData, null, 2)}</pre>
        </div>
    );
};

export default RevocationsComponent;